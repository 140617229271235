// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `rux-pop-up.commands_input-pop-up {
    margin-inline: var(--spacing-2);
  }
  rux-pop-up.commands_input-pop-up::part(arrow) {
    display: none;
  }
  
  rux-pop-up.commands_input-pop-up::part(popup-content) {
    margin-block-start: var(--spacing-1);
    /* the containing div - the button on the right */
    width: calc(100% - 9.375rem);
  }
  
  /*needed so that the absolutely positioned search div has something to be contained by*/
  .pass-plan div[slot="footer"] {
    position: relative;
  }
  
  rux-menu.commands_input-menu {
    height: 300px;
    overflow-y: scroll;
  }
  
  rux-menu.commands_input-menu rux-menu-item > * {
    pointer-events: none;
  }
  
  rux-menu.commands_input-menu h4.menu-title {
    margin: 0;
    padding: var(--spacing-2);
    font-weight: var(--font-weights-regular);
    color: var(--color-text-secondary);
    font-size: var(--font-size-sm);
    text-transform: uppercase;
  }
  
  .commands_no-match {
    height: 300px;
    overflow-y: scroll;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-inline: 2rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/systems/commands/passplan/SearchCommands/SearchCommands.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;EACjC;EACA;IACE,aAAa;EACf;;EAEA;IACE,oCAAoC;IACpC,iDAAiD;IACjD,4BAA4B;EAC9B;;EAEA,uFAAuF;EACvF;IACE,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,SAAS;IACT,yBAAyB;IACzB,wCAAwC;IACxC,kCAAkC;IAClC,8BAA8B;IAC9B,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,qBAAqB;IACrB,uBAAuB;IACvB,eAAe;IACf,oBAAoB;EACtB","sourcesContent":["rux-pop-up.commands_input-pop-up {\n    margin-inline: var(--spacing-2);\n  }\n  rux-pop-up.commands_input-pop-up::part(arrow) {\n    display: none;\n  }\n  \n  rux-pop-up.commands_input-pop-up::part(popup-content) {\n    margin-block-start: var(--spacing-1);\n    /* the containing div - the button on the right */\n    width: calc(100% - 9.375rem);\n  }\n  \n  /*needed so that the absolutely positioned search div has something to be contained by*/\n  .pass-plan div[slot=\"footer\"] {\n    position: relative;\n  }\n  \n  rux-menu.commands_input-menu {\n    height: 300px;\n    overflow-y: scroll;\n  }\n  \n  rux-menu.commands_input-menu rux-menu-item > * {\n    pointer-events: none;\n  }\n  \n  rux-menu.commands_input-menu h4.menu-title {\n    margin: 0;\n    padding: var(--spacing-2);\n    font-weight: var(--font-weights-regular);\n    color: var(--color-text-secondary);\n    font-size: var(--font-size-sm);\n    text-transform: uppercase;\n  }\n  \n  .commands_no-match {\n    height: 300px;\n    overflow-y: scroll;\n    display: flex;\n    align-content: center;\n    justify-content: center;\n    flex-wrap: wrap;\n    padding-inline: 2rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
