import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import {
    RuxAccordionItem, RuxButton,
    RuxOption, RuxSelect, RuxTable, RuxTableBody,
    RuxTableCell, RuxTableHeaderCell, RuxTableHeaderRow, RuxTableRow
} from "@astrouxds/react";
import { getObjects } from "../../../apis/objectApi";
import { getStations } from "../../../apis/stationApi";
import { createBooking, getBookings } from "../../../apis/bookingApi";
import { getPredictions } from "../../../apis/predictionAPi";
import { notify } from "../../../libs/notificationSystem/notificationManager";
import { useAuth } from "../../../auth/AuthContext";
import CalendarLink from './CalendarLink';

const FuturePassesTable = ({ setShouldPredictionRefresh, handleDeleteBookingClick, setUpdatedPredictions }) => {
    const [objects, setObjects] = useState([]);
    const [stations, setStations] = useState([]);
    const [predictions, setPredictions] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [selectedPredictions, setSelectedPredictions] = useState([]);
    const [selectedObjectIds, setSelectedObjectIds] = useState([]);
    const [selectedStationIds, setSelectedStationIds] = useState([]);
    const [predictionsCalculated, setPredictionsCalculated] = useState(false);
    const { hasPermission } = useAuth();
    const [bookingStatus, setBookingStatus] = useState({});

    const combinedData = [...predictions, ...bookings];
    const filteredData = combinedData.filter(item => {
        if (selectedObjectIds.length === 0 && selectedStationIds.length === 0) {
            return true;
        }
        const isBooking = item.booking_id !== undefined;
        const isPrediction = item.prediction_id !== undefined;
        const pairMatch = selectedObjectIds.some(objectId =>
            selectedStationIds.includes(item.station?.station_id) && String(objectId) === String(item.object?.norad_id)
        );
        return pairMatch && ((isBooking && !item.conflicting) || (isPrediction && !item.conflicting));
    });

    objects.sort((a, b) => a.name.localeCompare(b.name));
    stations.sort((a, b) => a.station_name.localeCompare(b.station_name));

    useEffect(() => {
        const loadData = async () => {
            try {
                const [objectsResponse, stationsResponse] = await Promise.all([
                    getObjects(),
                    getStations()
                ]);

                setObjects(objectsResponse || []);
                const allObjectIds = objectsResponse.map(object => String(object.norad_id));
                setSelectedObjectIds(allObjectIds);

                setStations(stationsResponse || []);
                const allStationIds = stationsResponse.map(station => station.station_id);
                setSelectedStationIds(allStationIds);
            } catch (error) {
                console.error('Error loading data:', error);
            }
        };
        loadData();
    }, []);

    useEffect(() => {
        const loadBookings = async () => {
            try {
                const res = await getBookings();
                const now = new Date();
                const futureBookings = res.filter(booking => new Date(booking.time_los) > now);
                setBookings(futureBookings || []);
            } catch (error) {
                console.error('Error loading bookings:', error);
            }
        };
        loadBookings();
        const interval = setInterval(() => {
            loadBookings();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const timeToNow = (time) => {
        const now = new Date();
        const futureTime = new Date(time);
        const diffMs = futureTime - now;
        if (diffMs > 0) {
            if (diffMs < 30 * 60 * 1000) {
                return '-' + moment.utc(diffMs).format('mm:ss');
            } else {
                return moment(futureTime).fromNow();
            }
        } else {
            return moment(futureTime).fromNow();
        }
    };

    const duration = (startTime, endTime) => moment.utc(new Date(endTime) - new Date(startTime)).format('mm:ss');

    const renderStatusIcon = (booking) => {
        const status_map = {
            OK: 'normal',
            WARNING: 'caution',
            STANDBY: 'standby',
            ERR: 'critical',
            null: 'off'
        };

        return status_map[booking.status?.level || null];
    };

    const handleFormSubmit = (event = null) => {
        if (event) {
            event.preventDefault();
        }

        const nowUTC = moment.utc();
        const time_to = nowUTC.clone().add(24, 'hours');
        const time_from = nowUTC;

        let normalizedSelectedObjectIds = Array.isArray(selectedObjectIds) ? selectedObjectIds : [selectedObjectIds];
        const selectedObjects = normalizedSelectedObjectIds.map(norad_id => ({ norad_id: parseInt(norad_id, 10) }));

        let normalizedSelectedStationIds = Array.isArray(selectedStationIds) ? selectedStationIds : [selectedStationIds];
        const selectedStations = normalizedSelectedStationIds.map(stationId => ({ station_id: stationId }));

        if (selectedObjects.length !== 0) {
            getPredictions(selectedObjects, selectedStations, time_from.toISOString(), time_to.toISOString())
                .then((fetchedPredictions) => {
                    fetchedPredictions?.sort((a, b) => moment(a.time_aos).diff(moment(b.time_aos)));
                    setPredictions(fetchedPredictions);
                    setUpdatedPredictions(fetchedPredictions);
                    setSelectedPredictions([]);
                    setPredictionsCalculated(true);
                })
                .catch((error) => {
                    console.error('Error calculating predictions:', error);
                    notify('Error calculating predictions: ' + error.message, 'critical');
                    setSelectedPredictions([]);
                });
        } else {
            setPredictions([]);
            notify('Please select the object.', 'standby');
        }
    };

    const handlePredictionSelection = async (index) => {
        const item = filteredData[index];
        if (item.prediction_id) {
            const predictionIndex = predictions.findIndex(pred => pred.prediction_id === item.prediction_id);
            if (predictionIndex === -1) {
                console.error('Prediction not found for predictionId:', item.prediction_id);
                return;
            }

            const selectedIndex = selectedPredictions.indexOf(predictionIndex);
            if (selectedIndex === -1) {
                setBookingStatus(prevStatus => ({
                    ...prevStatus,
                    [index]: 'serious'
                }));
                try {
                    await createBooking({ prediction_id: item.prediction_id });
                    console.log(`Prediction with ID ${item.prediction_id} booked successfully`);
                    handleFormSubmit();
                    setBookingStatus(prevStatus => ({
                        ...prevStatus,
                        [index]: 'normal'
                    }));
                    setTimeout(() => {
                        setBookingStatus(prevStatus => ({
                            ...prevStatus,
                            [index]: null
                        }));
                    }, 1000);
                } catch (error) {
                    console.error(`Error booking prediction with ID ${item.prediction_id}: ${error.message}`);
                    notify(`Error booking prediction with ID ${item.prediction_id}: ${error.message}`, 'critical');
                    setBookingStatus(prevStatus => ({
                        ...prevStatus,
                        [index]: 'critical'
                    }));
                    setTimeout(() => {
                        setBookingStatus(prevStatus => ({
                            ...prevStatus,
                            [index]: null
                        }));
                    }, 2000);
                }
            } else {
                setSelectedPredictions(prevSelected => prevSelected.filter(id => id !== predictionIndex));
            }
        } else {
            console.error('Invalid item type:', item);
        }
    };

    useEffect(() => {
        console.log('Predictions:', predictions);
        console.log('Selected Predictions:', selectedPredictions);
    }, [predictions, selectedPredictions]);

    const handleObjectSelection = (event) => {
        const selectedValues = event.target.value;
        setSelectedObjectIds(Array.isArray(selectedValues) ? selectedValues : [selectedValues]);
    };

    const handleStationSelection = (event) => {
        const selectedValues = event.target.value;
        setSelectedStationIds(Array.isArray(selectedValues) ? selectedValues : [selectedValues]);
    };

    return (
        <div>
            <div>
                <RuxAccordionItem>
                    <div slot="label">Filter and Calculate Predictions</div>
                    <form style={{ display: 'flex', alignItems: 'center', gap: '1rem' }} onSubmit={handleFormSubmit}>
                        <RuxSelect
                            style={{ width: '46%' }}
                            label="Select Object(s)"
                            multiple
                            value={selectedObjectIds}
                            onRuxchange={handleObjectSelection}
                        >
                            {objects.map(object => (
                                <RuxOption key={object.norad_id} value={String(object.norad_id)} label={object.name}></RuxOption>
                            ))}
                        </RuxSelect>
                        <RuxSelect
                            style={{ width: '46%' }}
                            label="Select Station(s)"
                            multiple
                            value={selectedStationIds}
                            onRuxchange={handleStationSelection}
                        >
                            {stations.map(station => (
                                <RuxOption key={station.station_id} value={station.station_id} label={station.station_name}></RuxOption>
                            ))}
                        </RuxSelect>
                        <RuxButton style={{ marginBottom: '-130px' }} type="submit">Calculate Predictions</RuxButton>
                    </form>
                </RuxAccordionItem>
            </div>
            <div>
                <RuxTable>
                    <RuxTableBody>
                        <RuxTableHeaderRow selected="false">
                            <RuxTableHeaderCell>Detail</RuxTableHeaderCell>
                            <RuxTableHeaderCell></RuxTableHeaderCell>
                            <RuxTableHeaderCell>Station</RuxTableHeaderCell>
                            <RuxTableHeaderCell>Object</RuxTableHeaderCell>
                            <RuxTableHeaderCell>AOS</RuxTableHeaderCell>
                            <RuxTableHeaderCell>Duration</RuxTableHeaderCell>
                            {hasPermission('booking_manage_*') && (
                                <RuxTableHeaderCell>Cancel</RuxTableHeaderCell>
                            )}
                        </RuxTableHeaderRow>

                        {filteredData.length === 0 && (
                            <RuxTableRow>
                                <td colSpan={7} style={{ textAlign: 'center' }}>
                                    No predictions or bookings available.
                                </td>
                            </RuxTableRow>
                        )}
                        {filteredData.length > 0 && filteredData
                            .sort((a, b) => new Date(a.time_aos).getTime() - new Date(b.time_aos).getTime())
                            .map((item, i) => (
                                <RuxTableRow
                                    selected={false}
                                    key={item.prediction_id || item.booking_id}
                                >
                                    <RuxTableCell>
                                        {item.prediction_id ? (
                                            item.conflicting ? (
                                                item.booking_id === null ? (
                                                    bookingStatus[i] === 'serious' ? (
                                                        <rux-status status='serious'></rux-status>
                                                    ) : (
                                                        <RuxButton
                                                            style={{ marginLeft: '3px' }}
                                                            secondary
                                                            onClick={() => handlePredictionSelection(i)}
                                                        >
                                                            Book Prediction
                                                        </RuxButton>
                                                    )
                                                ) : (
                                                    <Link style={{ marginLeft: '3px' }} to={`/bookings/${item.booking_id}`}>
                                                        <rux-button style={{ marginLeft: '-6px' }} secondary icon-only icon="launch" borderless />
                                                    </Link>
                                                )
                                            ) : (
                                                bookingStatus[i] === 'serious' ? (
                                                    <rux-status status='serious'></rux-status>
                                                ) : bookingStatus[i] === 'critical' ? (
                                                    <rux-status status='critical'></rux-status>
                                                ) : (
                                                    <RuxButton
                                                        style={{ marginLeft: '3px' }}
                                                        secondary
                                                        onClick={() => handlePredictionSelection(i)}
                                                    >
                                                        Book Prediction
                                                    </RuxButton>
                                                )
                                            )
                                        ) : (
                                            bookingStatus[i] === 'normal' ? (
                                                <rux-status status='normal'></rux-status>
                                            ) : (
                                                <Link style={{ marginLeft: '3px' }} to={`/bookings/${item.booking_id}`}>
                                                    <rux-button style={{ marginLeft: '-6px' }} secondary icon-only icon="launch" borderless />
                                                </Link>
                                            )
                                        )}
                                    </RuxTableCell>
                                    {item.booking_id ? (
                                        <RuxTableCell>
                                            <rux-status status={renderStatusIcon(item)}></rux-status>
                                        </RuxTableCell>
                                    ) : (
                                        <RuxTableCell></RuxTableCell>
                                    )}
                                    <RuxTableCell>{item.station?.station_name || 'n/a'}</RuxTableCell>
                                    <RuxTableCell>{item.object?.name || 'n/a'}
                                        <br />
                                        <span style={{ fontSize: '0.8em', color: "gray" }}>{item.object?.norad_id || 'n/a'}</span>
                                    </RuxTableCell>
                                    <RuxTableCell>{timeToNow(item.time_aos)}
                                        <CalendarLink booking={item} />
                                        <br />
                                        <span style={{ fontSize: '0.8em', color: "gray" }}>{moment(item.time_aos).utc().format("HH:mm:ss")}</span>
                                    </RuxTableCell>
                                    <RuxTableCell>{duration(item.time_aos, item.time_los)}
                                        <br />
                                        <span style={{ fontSize: '0.8em', color: "gray" }}>{item.maximum
                                            ? "max. [" + parseFloat(item.maximum.azimuth).toFixed(0) + "," + parseFloat(item.maximum.elevation).toFixed(0) + "]"
                                            : 'n/a'}</span>
                                    </RuxTableCell>
                                    {hasPermission('booking_manage_*') && (
                                        <RuxTableCell>
                                            {item.booking_id && (
                                                <rux-button
                                                    secondary
                                                    icon-only
                                                    icon="remove-circle-outline"
                                                    borderless
                                                    onClick={(event) => {
                                                        handleDeleteBookingClick(item.booking_id);
                                                        if (!predictionsCalculated) {
                                                            setShouldPredictionRefresh(true);
                                                        }
                                                        if (predictionsCalculated) {
                                                            handleFormSubmit(event);
                                                        }
                                                    }}
                                                />
                                            )}
                                        </RuxTableCell>
                                    )}
                                </RuxTableRow>
                            ))}
                    </RuxTableBody>
                </RuxTable>
            </div>
        </div>
    );
};

export default FuturePassesTable;